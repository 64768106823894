















































































import { computed, defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import Layout from '@/components/Layout.vue'
import LoanRecap from '@/components/LoanRecap.vue'

import { isDashboardEnabled } from '@/composables/ui'

export default defineComponent({
  name: 'dashboard',
  components: { Layout, LoanRecap },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const { user, loans, mortgages } = useState('user', {
      user: 'data',
      loans: 'loans',
      mortgages: 'mortgages',
    })
    const { FETCH_LOANS: fetchLoans, FETCH_MORTGAGES: fetchMortgages } = useActions('user', [
      'FETCH_LOANS',
      'FETCH_MORTGAGES',
    ])
    const isReady = ref(false)

    const loanList = computed(() =>
      loans.value.filter(l => (props.archive ? l.status === 'ARCHIVE' : l.status !== 'ARCHIVE'))
    )

    const mortgageList = computed(() =>
      mortgages.value.filter(m => (props.archive ? m.status === 'ARCHIVE' : m.status !== 'ARCHIVE'))
    )

    onBeforeMount(() => {
      const isDashBoardEnabled = isDashboardEnabled()

      if (!isDashBoardEnabled) {
        ctx.root.$router.push({
          name: 'DocumentsSign',
          query: ctx.root.$route.query,
        })

        return
      }

      Promise.all([fetchLoans(), fetchMortgages()]).then(() => {
        setTimeout(() => {
          isReady.value = true
        }, 500)
      })
    })

    return { isReady, user, loanList, mortgageList }
  },
})
